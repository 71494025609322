import { app } from './firebase'


import { getFirestore, collection, query, limit, getDocs, setDoc, addDoc, doc, connectFirestoreEmulator, Timestamp, FieldValue, Filter, deleteDoc } from 'firebase/firestore';
// const serviceAccount = require('./bettergiftbuddy-website-442ed400b175.json');
const monthsLookup = { 0: "January", 1: "February", 2: "March", 3: "April", 4: "May", 5: "June", 6: "July", 7: "August", 8: "September", 9: "October", 10: "November", 11: "December" }

const db = getFirestore(app);
// connectFirestoreEmulator(db, '127.0.0.1', 9000);

// Util String Functions

function capitalizeWord(word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

// STRIPE RELATED OPERATIONS

async function getProducts(){
    const productsCollection = collection(db, "products")
    const products = await getDocs(productsCollection)
}

// BLOG RELATED FIRESTORE ACTIONS
async function getBlogs() {
    const allBlogsQuery = query(
        collection(db, 'blogs'),
    );
    const blogsSnapshot = await getDocs(allBlogsQuery)
    // console.log(blogsSnapshot)
    const allBlogs = []
    // blogsSnapshot.forEach((post) => allBlogs.push({ ...post.data(), id: post.id }))

    return blogsSnapshot
}

// Create Blog Function

async function getPost(title) {
    const post = doc(db, 'posts/')
}

// REMINDERS AND DASHBOARD OPERATIONS

// Create Occasion for Reminder
async function createOccasion(db, first_name, last_name, userid, occasion, date) {
    // console.log(userid, date)
    const userRef = collection(db, `/users/${userid}/occasions/`)
    const occasionData = {
        first_name: capitalizeWord(first_name),
        last_name: capitalizeWord(last_name),
        description: capitalizeWord(occasion),
        date: date
        }
    const newDoc = await addDoc(userRef, occasionData).then(() => { alert("Successfully added occasion!") })

}

// Get Specific Occasion for Detail Page
async function getOccasion(db, uid) {
    const userRef = collection(db, `/users/${uid}/occasions`);
    const occasion = await getDocs(userRef);

}

// Remove Specific Occasion
async function removeOccasion(db, userid, docRef) {
    // console.log(`Remove Occasion docRef: ${docRef}`)
    const userOccasionDocRef = doc(db, `/users/${userid}/occasions`, docRef)
    try {
        await deleteDoc(userOccasionDocRef);
        alert("Occasion deleted! You won't receive reminders for this occasion anymore.");
    } catch (error) {
        console.error('Error deleting occasion:', error.message);
        // Handle error as needed
    }
}

// Get All Occasions for Loading Dashboard
async function getOccasions(db, userid) {
    const occasionsQuery = query(
        collection(db, `/users/${userid}/occasions`),
        limit(20))

    const occasionsQuerySnapshot = await getDocs(occasionsQuery)
    return occasionsQuerySnapshot
}


// CHAT AI BOT RELATED OPERATIONS
// Chat Related Operations
async function submitPrompt(db, userid, prompt) {

}

async function receiveResponse(db, userid, response) {

}
// Get product recommendations and 



export {
    // createPost,
    getPost,
    getBlogs,
    createOccasion,
    getOccasion,
    removeOccasion,
    getOccasions,
    // createOrCheckCollection,
    submitPrompt,
    receiveResponse,
    db,
}
